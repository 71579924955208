const SERVER_ORIGIN = '/api' // use proxy only

export type TResponse<T> = {
    data: T | null,
    errors: string[]
}

export default async function request<T>(path: string, params?: RequestInit) {
    return fetch(`${SERVER_ORIGIN}${path}`, {
        headers: {
            'Content-type': 'application/json',
        },
        mode: 'cors',
        ...params,
    }).then(async (resp):Promise<TResponse<T>> => {
        try {
            if (
                params?.method?.toLowerCase() !== 'head' && (
                    resp.headers.get('content-type') === 'application/json'
                    || resp.headers.get('content-type') === 'application/json; charset=utf-8'
                )
            ) {
                const body = await resp.json()
                if (resp.ok) {
                    return {
                        data: body,
                        errors: [],
                    }
                }
                return {
                    data: null,
                    errors: body.errors || [body.message],
                }
            }
            if (resp.ok) {
                return {
                    data: await resp.text() as unknown as T, // should be a string
                    errors: [],
                }
            }
            return {
                data: null, // should be a string
                errors: [await resp.text()],
            }
        } catch (error) {
            const message = (error as Error).toString()
            return {
                data: null,
                errors: [message],
            }
        }
    })
}
